<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Criar Fatura" />

            <div class="row">
               <div class="col-md-6">
                  <div class="card bg-dark p-3">
                     <div class="mb-3">
                        <label class="form-label">Cliente</label>
                        <input type="text" readonly class="form-control" 
                        :value="`${client.name} (${client.email})`">
                     </div>

                     <div class="row mb-3">
                        <div class="col-8">
                           <label class="form-label">Selecione o Plano</label>
                           <select v-model="form.plan_id" class="form-select" aria-label="Plano">
                              <option 
                                 v-for="plan in plans" 
                                 :key="plan.id" 
                                 :value="plan.id">
                                 {{ `${plan.name} (R$ ${plan.price.toFixed(2)})` }}
                              </option>
                           </select>
                        </div>
                        <div class="col-4">
                           <label class="form-label">Período</label>
                           <select v-model="form.period" class="form-select" aria-label="Meses">
                              <option selected disabled>Selecione</option>
                              <option value="1">Mensal</option>
                              <option value="3">3 meses</option>
                              <option value="6">6 meses</option>
                              <option value="12">1 ano</option>
                              <option value="24">2 anos</option>
                           </select>
                        </div>
                     </div>

                     <div class="row mb-3">
                        <div class="col-4">
                           <label class="form-label">Descontos (ex: 9.90)</label>
                           <input v-model="form.discount" type="number" class="form-control">
                        </div>
                        <div class="col-4">
                           <label class="form-label">Início</label>
                           <input v-model="form.start_at" type="date" class="form-control">
                        </div>
                        <div class="col-4">
                           <label class="form-label">Vencimento da fatura</label>
                           <input v-model="form.due_date" type="date" class="form-control">
                        </div>
                     </div>

                     <div class="mb-3">
                           <label class="form-label">Meio de Pagamento</label>
                           <select v-model="form.payment_method" class="form-select" aria-label="Meio de Pagamento">
                              <option :value="PaymentMethod.Pix">Pix</option>
                              <option :value="PaymentMethod.Cartao">Cartão (checkout)</option>
                              <option :value="PaymentMethod.Boleto">Boleto</option>
                              <option :value="PaymentMethod.Voucher">Voucher</option>
                           </select>
                        </div>

                     <div class="mb-2 mt-1">
                        <button @click="create" type="button" class="btn btn-light">Criar fatura</button>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import PaymentMethodEnum from '@/enums/PaymentMethodEnum'

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },

   data() {
      return {
         client: {},
         form: {
            plan_id: 7,
            period: 1,
            discount: 0,
            start_at: null,
            user_id: null,
            due_date: null,
            payment_method: '3',
         },
         plans: [],
         PaymentMethod: null,
      }
   },
   
   created() {
      this.PaymentMethod = PaymentMethodEnum; 
   },

   mounted() {
      this.findClient();
      this.fetchPlans();
      this.form.start_at = this.$dayjs().format('YYYY-MM-DD');
      this.form.due_date = this.$dayjs().add(4, 'day').format('YYYY-MM-DD');
   },

   methods: {
      async create() {
         let loader = this.$loading.show();

         try {

            this.form.library_id = this.client.library.id;

            const response = await this.$axios.post(`master/contracts`, this.form);
            this.$notify({title: response.data.message, type: 'success'});

            this.$router.push(`/clients/${this.$route.params.id}/show`) ;

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message, type: 'error'});
         }
         loader.hide();
      },

      async findClient() {
         let loader = this.$loading.show();

         try {
            const response = await this.$axios.get(`master/clients/${this.$route.params.id}`);
            this.client = response.data;
            this.form.user_id = this.$route.params.id;
            this.form.start_at = this.$dayjs(this.client.contracts[0].expires_at).add(1, 'day').format('YYYY-MM-DD');

         } catch (error) {
            this.$notify({title: error.response?.data.message ?? 'Erro', type: 'success'});
            console.log(error);
         }

         loader.hide();
      },

      async fetchPlans() {
         let loader = this.$loading.show();
         try {
            const response = await this.$axios.get(`plans`);
            this.plans = response.data;
         } catch (error) {
            console.log(error);
         }
         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped>
</style>