<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Editar biblioteca" />

            <div class="row">
               <div class="col-md-6">
                  Conteudo
               </div>
            </div>

         </div>
      </div>

   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'

export default {
   name: "EditPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },

}
</script>