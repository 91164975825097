<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Clientes" />
            
            <div class="row">
               <div class="col-md-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div class="input-group mb-4">
                        <div class="me-3">
                           <label>Responsável ou e-mail</label>
                           <input v-model="queryClient.name" type="text" class="form-control" id="formControlName" placeholder="Responsável ou e-mail">
                        </div>
                        <div class="me-3">
                           <label>Filtro</label>
                           <select v-model="queryClient.param" class="form-select" aria-label="Parâmetro">
                              <option value="name">Nome</option>
                              <option value="email">E-mail</option>
                           </select>
                        </div>
                        <div class="me-3">
                           <label>Status da conta</label>
                           <select v-model="queryClient.access_removed" class="form-select" aria-label="Parâmetro">
                              <option value="0">Ativo</option>
                              <option value="1">Removido</option>
                           </select>
                        </div>
                        <div class="me-3">
                           <div class="mt-4"></div>
                           <button 
                              @click="findClients" 
                              type="button" 
                              class="btn btn-light">
                              Buscar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="clients.length > 0" class="table-responsive">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th scope="col">Responsável</th>
                                 <th>Biblioteca</th>
                                 <th class="text-center">Acesso</th>
                                 <th class="text-center">Tipo</th>
                                 <th></th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="client in clients" :key="client.id">
                                 <td>{{ client.id }}</td>
                                 <td>{{ client.name }}</td>
                                 <td>
                                    <span class="me-3">{{ client.library?.name ?? 'Não Criada' }}</span>
                                    <span 
                                       v-if="!client.library"
                                       @click="accountRemove(client.id)" 
                                       role="button"
                                       class="badge bg-danger px-3 me-2">
                                       Bloquear</span>
                                    <span 
                                       v-if="!client.library"
                                       onclick="alert('Breve')" 
                                       role="button"
                                       class="badge bg-primary px-3">
                                       Criar</span>
                                 </td>
                                 <td class="text-center">
                                    <span v-if="client.access_removed == 0" class="badge bg-success">Ativo</span>
                                    <span v-else-if="client.access_removed == 1" class="badge bg-danger">Removido</span>
                                 </td>
                                 <td class="text-center">
                                    <span 
                                       v-if="client.contracts[0].plan_id != 5"
                                       class="badge bg-primary">
                                       Pagante
                                    </span>
                                    <span v-else class="badge bg-secondary">
                                       Trial
                                    </span>
                                 </td>
                                 <td class="text-center">
                                    <button 
                                       class="btn btn-sm btn-outline-light" 
                                       data-bs-toggle="offcanvas" 
                                       data-bs-target="#offcanvasExample"
                                       @click="fetchInvoices(client.contracts[0].id)">
                                       Faturas
                                    </button>
                                 </td>
                                 <td>
                                    <router-link 
                                       :to="`/clients/${client.id}/show`" 
                                       :class="`btn btn-sm btn-outline-light`">
                                       Detalhes</router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Sem contas criadas
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
      <ButtonAddComponent action="/clients/create" />

      <!-- Offcanvas -->
      <div class="offcanvas bg-dark offcanvas-end" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
         <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasExampleLabel">Faturas do cliente</h5>
            <button type="button" class="btn-close bg-white" data-bs-dismiss="offcanvas" aria-label="Close"></button>
         </div>
         <div class="offcanvas-body">
            <div v-for="invoice in invoices" :key="invoice.id" class="card text-white bg-secondary mb-3">
               <div class="p-3">
                  <div class="row">
                     <div class="col fw-semibold">
                        Fatura {{ invoice.month }} / {{ invoice.year }}
                     </div>
                     <div v-if="invoice.status == 1" class="col text-end text-warning fw-bold">
                        <span class="me-2">Pendente</span>
                        <i class="bi bi-circle-fill"></i>
                     </div>
                     <div v-else-if="invoice.status == 2" class="col text-end text-white fw-bold">
                        <div class="badge bg-success px-3 py-1">
                           <span class="me-2">Pago</span>
                           <i class="bi bi-circle-fill"></i>
                        </div>
                     </div>
                     <div v-else-if="invoice.status == 3" class="col text-end text-danger fw-bold">
                        <span class="me-2">Cancelado</span>
                        <i class="bi bi-circle-fill"></i>
                     </div>
                  </div>
                  <div>Valor: R$ {{ invoice.total.toFixed(2) }}</div>
                  <div v-if="invoice.due_date">
                     Pago em {{ $dayjs(invoice.due_date).format("DD/MM/YYYY") }}
                  </div>

               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ButtonAddComponent from '@/components/ButtonAddComponent.vue';

export default {
   name: "IndexPage",
   components: {
    PageTitleComponent,
    NavbarComponent,
    ButtonAddComponent
},
   
   data() {
      return {
         queryClient: {
            name: "",
            param: "name",
            payment_status: "pending",
            access_removed: 0,
         },
         clients: [],
         invoices: []
      }
   },
   mounted() {
      this.findClients();
   },

   methods: {
      async findClients() {
         let loader = this.$loading.show();
         this.clients = [];

         try {
            const urlParams = new URLSearchParams();
            urlParams.set("param", this.queryClient.param);
            urlParams.set("name", this.queryClient.name);
            urlParams.set("access_removed", this.queryClient.access_removed);

            const response = await this.$axios.get(`master/clients?${urlParams}`);
            this.clients = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },

      async fetchInvoices(contractId) {
         let loader = this.$loading.show();
         this.invoices = [];

         try {
            const urlParams = new URLSearchParams();
            urlParams.set("contract_id", contractId);
            const response = await this.$axios.get(`master/invoices?${urlParams}`);
            this.invoices = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },

      async accountRemove(id) {
         let loader = this.$loading.show();
         
         const form = {
            "user_id": id,
            "access_removed": 1
         };

         try {
            await this.$axios.put(`master/clients/${id}/account-status`, form);
            await this.findClients();
         } catch (error) {
            console.log(error);
         }

         loader.hide();
      }
   },
}
</script>

<style lang="scss" scoped>
</style>