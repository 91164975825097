<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Contratos" />
            
            <div class="row">
               <div class="col-md-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div class="input-group mb-1">
                        <div class="me-3 mb-3">
                           <label>Responsável ou e-mail</label>
                           <input v-model="queryClient.name" type="text" class="form-control" id="formControlName" placeholder="Responsável ou e-mail">
                        </div>
                        <div class="me-3 mb-3">
                           <label>Filtro</label>
                           <select v-model="queryClient.param" class="form-select" aria-label="Parâmetro">
                              <option value="name">Nome</option>
                              <option value="email">E-mail</option>
                           </select>
                        </div>
                        <div class="me-3 mb-3">
                           <label>Status</label>
                           <select v-model="queryClient.payment_status" class="form-select" aria-label="Status">
                              <option value="1">Pendente</option>
                              <option value="2">Pago</option>
                              <option value="3">Cancelado</option>
                           </select>
                        </div>
                        <div class="me-3">
                           <div class="mt-4"></div>
                           <button 
                              @click="findContracts" 
                              type="button" 
                              class="btn btn-light">
                              Buscar</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

            <div class="row mt-5">
               <div class="col-12">
                  <div class="card pt-3 px-3 bg-dark">
                     <div v-if="contracts.length > 0" class="table-responsive">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th scope="col">Responsável</th>
                                 <th>Plano</th>
                                 <th>Valor</th>
                                 <th>Início</th>
                                 <th class="text-center">Pagamento</th>
                                 <th>Vencimento</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="contract in contracts" :key="contract.id">
                                 <td>{{ contract.id }}</td>
                                 <td>{{ contract.user.name }}</td>
                                 <td>{{ contract.plan.name }}</td>
                                 <td>R$ {{ (contract.final_price).toFixed(2) }}</td>
                                 <td>{{ $dayjs(contract.start_at).format("DD/MM/YYYY") }}</td>
                                 <td class="text-center">
                                    <span v-if="contract.status == 1" class="badge bg-warning">Aguardando</span>
                                    <span v-else-if="contract.status == 2" class="badge bg-success">Pago</span>
                                    <span v-else-if="contract.status == 3" class="badge bg-danger">Cancelado</span>
                                 </td>
                                 <td>{{ $dayjs(contract.due_date).format("DD/MM/YYYY") }}</td>
                                 <td>
                                    <router-link 
                                       :to="`/contracts/${contract.id}/show`" 
                                       class="badge bg-dark me-3" 
                                       style="cursor: pointer;">
                                       Detalhes</router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Contratos não localizados
                     </div>
                  </div>   
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
   },
   
   data() {
      return {
         queryClient: {
            name: "",
            param: "name",
            payment_status: "1",
         },
         contracts: [],
         form: {
            id: null,
            reason: "",
         },
      }
   },
   mounted() {
      this.findContracts();
   },

   methods: {
      async findContracts() {
         let loader = this.$loading.show();
         this.contracts = [];

         try {
            const urlParams = new URLSearchParams();
            urlParams.set("param", this.queryClient.param);
            urlParams.set("name", this.queryClient.name);
            urlParams.set("status", this.queryClient.payment_status);

            const response = await this.$axios.get(`master/contracts?${urlParams}`);
            this.contracts = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },

      copyText(text) {
         navigator.clipboard.writeText(text);
         return this.$notify({title: 'Copiado com sucesso', type: 'success'});
      },
   },
}
</script>
