<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Dashboard" :hideButtonBack="true" />

            <div class="row">
               <div class="col-md-4 mb-3">
                  <DashboardCardComponent 
                     title="Contratos"
                     icon="contracts.png"
                     :totalItems="`${resume.total_contracts} contratos ativos`"
                     actionIndex="/contracts"
                     actionCreate="/contracts/create"
                  />
               </div>
               <div class="col-md-4 mb-3">
                  <DashboardCardComponent 
                     title="Clientes"
                     icon="manager.png"
                     :totalItems="`${resume.total_clients} contas no Libmin`"
                     actionIndex="/clients"
                     actionCreate="/clients/create"
                  />
               </div>
               <div class="col-md-4 mb-3">
                  <DashboardCardComponent 
                     title="Cidades"
                     icon="cities-map.png"
                     :totalItems="`${resume.total_cities} municípios cadastrados`"
                     actionIndex="/cities"
                     actionCreate="/cities/create"
                  />
               </div>

               <div class="col-md-4 mb-3">
                  <DashboardCardComponent 
                     title="Categorias Dewey"
                     icon="categories.png"
                     totalItems="Cadastradas no sistema"
                     actionIndex="/categories/dewey"
                     actionCreate="/"
                  />
               </div>

               <div class="col-md-4 mb-3">
                  <DashboardCardComponent 
                     title="Logs"
                     icon="logs.png"
                     totalItems="As últimas ações no Libmin"
                     actionIndex="/logs"
                     actionCreate="/"
                  />
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import DashboardCardComponent from '@/components/DashboardCardComponent.vue';
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'

export default {
   name: "IndexPage",
   components: {
      DashboardCardComponent, 
      PageTitleComponent,
      NavbarComponent,
   },
   
   data() {
      return {
         resume: {
            total_contracts: 0,
            total_clients: 0,
            total_cities: 0,
         },
      }
   },

   mounted() {
      this.findResume();
   },

   methods: {
      async findResume() {
         let loader = this.$loading.show();
         this.contracts = [];
         
         try {
            const response = await this.$axios.get(`master/resume`);
            this.resume = response.data;

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },
   },
}
</script>