<template>
   <div>
      <NavbarComponent />

      <div class="container">
         <div class="page-content-wrapper">

            <PageTitleComponent title="Detalhes do cliente" />

            <div class="row">
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3">
                     <h5>Gestor</h5>
                     
                     <div class="mb-3">
                        <div>{{ client.name }}</div>
                     </div>

                     <div class="mb-3">
                        {{ (client.document != null) ? client.document : 'Sem documento' }}
                     </div>

                     <div class="mb-3">
                        E-mail: {{ client.email }}
                     </div>

                     <div class="mb-3">
                        <span class="me-1">Contato: </span>
                        <a :href="`https://wa.me/55${client.phone}?text`" target="_blank">
                           <i class="bi bi-whatsapp"></i>
                        </a>
                        <span class="ms-2">{{ client.phone }}</span>
                     </div>

                     <div class="mb-3">
                        Criado em {{ $dayjs(client.created_at).format('DD/MM/YYYY HH:MM') }}
                     </div>

                     <div>
                        <button 
                           v-if="client.access_removed == 0"
                           @click="changeAccountStatus(1)"
                           class="btn btn-sm btn-danger rounded-4 px-3">Remover acesso</button>

                        <button 
                           v-if="client.access_removed == 1"
                           @click="changeAccountStatus(0)"
                           class="btn btn-sm btn-outline-success px-3 rounded-4">Reestabelecer acesso</button>

                        <button 
                           @click="$router.push(`/notifications/${client.id}`)"
                           class="btn btn-sm btn-secondary rounded-4 px-3 ms-3">Enviar notificação</button>

                        <button 
                           data-bs-toggle="modal" 
                           data-bs-target="#integrationModal"
                           class="btn btn-sm btn-secondary rounded-4 px-3 ms-3">Criar integração</button>

                     </div>

                  </div>
               </div>
               <div class="col-md-6 mb-5">
                  <div class="card bg-dark p-3">
                     <h5>Biblioteca</h5>
                     
                     <div class="mb-3">
                        <div>{{ client.library.name }}</div>
                     </div>

                     <div class="mb-3">
                        Código: {{ client.library.code }}
                     </div>

                     <div class="mb-3">
                        Endereço: {{ `${client.library.address} ${client.library.address}` }}
                     </div>

                     <div class="mb-3">
                        {{ client.library.city?.name ?? 'Sem Cidade' }} / {{ client.library.state?.name ?? 'Sem Estado' }}
                     </div>

                     <div class="mb-3">
                        <span class="me-2">Contato: </span>
                        <span class="badge bg-light text-dark me-2">{{client.library.public_phone ?? 'sem telefone' }}</span>
                        <span class="badge bg-light text-dark">{{client.library.public_email ?? 'sem email'}}</span>
                     </div>

                     <div class="mb-3">
                        <router-link 
                           :to="`/libraries/${client.library.id}/edit`"
                           class="btn btn-sm btn-secondary px-3 rounded-4">
                           Editar
                        </router-link>
                     </div>
                  </div>
               </div>

               <div class="row mb-5">
                  
                  <div class="col-12 fw-bolder fs-5 mb-3">PKIs</div>

                  <div class="col-md-3 mb-2">
                     <div class="card-pki p-4 text-center">
                        <span class="fw-bolder">{{ client.library.books_count }}</span>
                        <div>Livros</div>
                     </div>
                  </div>

                  <div class="col-md-3 mb-2">
                     <div class="card-pki p-4 text-center">
                        <span class="fw-bolder">{{ client.library.readers_count }}</span>
                        <div>Leitores</div>
                     </div>
                  </div>

                  <div class="col-md-3 mb-2">
                     <div class="card-pki p-4 text-center">
                        <span class="fw-bolder">{{ client.library.loans_count }}</span>
                        <div>Empréstimos</div>
                     </div>
                  </div>

                  <div class="col-md-3 mb-2">
                     <div class="card-pki p-4 text-center">
                        <span class="fw-bolder">{{ client.library.users_library?.length }}</span>
                        <div>Administradores</div>
                     </div>
                  </div>

                  <div class="col"></div>
               </div>

               <div class="col-md-12 mb-5">
                  <div class="card bg-dark p-3">
                     <h5 class="mb-3">Contratos</h5>
                     
                     <div class="table-responsive mb-3">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th>Preço</th>
                                 <th>Vencimento</th>
                                 <th class="text-center">Status</th>
                                 <th>Renovação</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="city in client.contracts" :key="city.id">
                                 <td>{{ city.id }}</td>
                                 <td>R$ {{ city.final_price.toFixed(2) }}</td>
                                 <td>{{ $dayjs(city.due_date).format("DD/MM/YYYY") }}</td>
                                 <td class="text-center">
                                    <span 
                                       v-if="city.status == 1" 
                                       class="badge bg-warning"
                                       :title="city.reason_status">
                                       Aguardando
                                    </span>
                                    <span v-else-if="city.status == 2" class="badge bg-success">Pago</span>
                                    <span v-else-if="city.status == 3" class="badge bg-danger">Cancelado</span>
                                 </td>
                                 <td>{{ $dayjs(city.expires_at).format("DD/MM/YYYY") }}</td>
                                 <td>
                                    <router-link :to="`/contracts/${city.id}/show`" class="btn btn-sm btn-outline-info">Detalhes</router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>

               <!-- Faturas -->
               <div class="col-md-12 mb-5">
                  <div class="card bg-dark p-3">
                     <h5 class="mb-3">Faturas</h5>
                     
                     <div class="table-responsive mb-3">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th>Fatura</th>
                                 <th>Valor</th>
                                 <th class="text-center">Status</th>
                                 <th>Comissão</th>
                                 <th>Comissão Paga</th>
                                 <th></th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="invoice in invoices" :key="invoice.id">
                                 <td>{{ invoice.month }} / {{ invoice.year }}</td>
                                 <td>R$ {{ invoice.total.toFixed(2) }}</td>
                                 <td>{{ $dayjs(invoice.due_date).format("DD/MM/YYYY") }}</td>
                                 <td class="text-center">
                                    <span 
                                       v-if="invoice.status == 1" 
                                       class="badge bg-warning"
                                       :title="invoice.annotation">
                                       Aguardando
                                    </span>
                                    <span v-else-if="invoice.status == 2" class="badge bg-success">Pago</span>
                                    <span v-else-if="invoice.status == 3" class="badge bg-danger">Cancelado</span>
                                 </td>
                                 <td>{{ $dayjs(invoice.due_date).format("DD/MM/YYYY") }}</td>
                                 <td>
                                    <router-link :to="`/invoices/${invoice.id}/show`" class="btn btn-sm btn-outline-info">Detalhes</router-link>
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                  </div>
               </div>

               <div class="col-md-12 mb-3">
                  <div class="card bg-dark p-3">
                     <h5 class="mb-3">Atividade</h5>
                     
                     <div v-if="client.logs.length > 0" class="table-responsive">
                        <table class="table table-dark table-hover">
                           <thead>
                              <tr>
                                 <th style="max-width: 20px;">#</th>
                                 <th>Ação</th>
                                 <th>Modelo</th>
                                 <th>Contexto</th>
                                 <th>Data</th>
                              </tr>
                           </thead>
                           <tbody>
                              <tr v-for="log in client.logs" :key="log.id">
                                 <td>{{ log.id }}</td>
                                 <td>{{ actionRecupere(log.action) ?? ' - ' }}</td>
                                 <td>{{ log.model }}</td>
                                 <td style="max-width: 300px;">{{ contextFormat(log.context) }}</td>
                                 <td>
                                    {{ $dayjs(log.created_at).format("DD/MM/YYYY HH:mm:ss") }}
                                 </td>
                              </tr>
                           </tbody>
                        </table>
                     </div>
                     <div v-else class="text-center pb-3">
                        Sem logs para o período
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

      <ButtonAddComponent :action="`/contracts/${client.id}/create`" />

      <!-- Modal -->
      <div class="modal fade" id="integrationModal" tabindex="-1" aria-labelledby="integrationModalLabel" aria-hidden="true">
         <div class="modal-dialog">
            <div class="modal-content">
               <div class="modal-header">
                  <h1 class="modal-title fs-5" id="integrationModalLabel">Criar Integração</h1>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <p>Confirma a integração com o sistema Kinto Escola?<br>
                  Enviaremos um e-mail para o cliente com a chave de integração.</p>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
                  <button @click="createSecretIntegration" type="button" class="btn btn-primary">Cadastrar</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>
import PageTitleComponent from '@/components/PageTitleComponent.vue';
import NavbarComponent from '@/components/NavbarComponent'
import ConstantsApp from '../../../constantsApp';
import ButtonAddComponent from '@/components/ButtonAddComponent.vue';

export default {
   name: "IndexPage",
   components: {
      PageTitleComponent,
      NavbarComponent,
      ButtonAddComponent
   },

   created() {
      this.fetchClient();
      this.fetchInvoices();
      this.ufs = ConstantsApp.Estados;
   },
   
   data() {
      return {
         client: {
            name: '',
            library: {
               state: {
                  name: '',
               },
               city: {
                  name: '',
               }
            },
            contracts: [],
            logs: [],
         },
         ufs: [],
         invoices: [],
      }
   },
   
   methods: {
      async fetchClient() {
         let loader = this.$loading.show();
         
         try {
            const response = await this.$axios.get(`master/clients/${this.$route.params.id}`);
            this.client = response.data;
            console.log(response.data)

         } catch (error) {
            console.log(error);
         }

         loader.hide();
      },

      async fetchInvoices() {
         let loader = this.$loading.show();
         try {
            const response = await this.$axios.get(`master/invoices?contract_id=${this.$route.params.id}05`);
            this.invoices = response.data;

         } catch (error) {
            console.log(error);
         }
         loader.hide();
      },

      async changeAccountStatus(status) {
         let loader = this.$loading.show();
         
         try {
            await this.$axios.put(
               `master/clients/${this.$route.params.id}/account-status`, 
               {user_id: this.$route.params.id, access_removed: status}
            );
            
            this.$notify({title: "Alterado com sucesso", type: 'success'});

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.message ?? 'Ocorreu um erro', type: 'error'});
         }

         loader.hide();

         this.findClient();
      },

      async createSecretIntegration() {
         let loader = this.$loading.show();
         
         const data = {
            "library_id": this.client.library.id,
            "partner_id": 1,
            "scope": {
               "readers": ["create", "approve", "remove"],
               "loans": ["index"]
            }
         };

         try {
            const response = await this.$axios.post(`master/integrators`, data);
            this.$notify({title: response.data.message, type: 'success'});

         } catch (error) {
            console.log(error);
            this.$notify({title: error.response.data.message ?? 'Erro', type: 'error'});
         }

         loader.hide();
      },

      contextFormat(item) {
         const data = JSON.parse(item);
         const values = Object.values(data);
         return values.join(' | '); 
      },

      actionRecupere(action) {
         switch (action) {
            case 'create': return 'Criou';
            case 'update': return 'Atualizou';
            case 'delete': return 'Excluiu';
            case 'export': return 'Exportou';
            case 'view'  : return 'Acessou';
            default: return action;
         }
      },
   },
}
</script>

<style scoped>
.card-pki {
   background-color: #212529; 
   color:#a9a9b2;
   border-radius: 14px;
}
</style>